<template>
  <!-- 设置新密码 -->
  <div id="house-login" class="layui-fulid">
    <div class="layui-form">
      <p>设置新密码</p>
      <h4>
        <span>【{{ username }}】</span>你好，请设置新密码
      </h4>
      <div class="layui-input-block login">
        <input
          v-model="psw"
          class="layui-input"
          lay-verify="required"
          placeholder="请设置密码"
          required
          type="password"
        />
      </div>
      <div class="layui-input-block login">
        <input
          v-model="psw1"
          class="layui-input password"
          lay-verify="required"
          placeholder="请确认密码"
          required
          type="password"
        />
      </div>
      <button
        class="layui-btn login"
        lay-filter="user-login"
        lay-submit
        style="margin-top: 30px"
        @click="resetPassword"
      >
        重置确定
      </button>
    </div>
  </div>
</template>

<script>
import { resetPsw } from "../../service/index";

export default {
  data() {
    return {
      token: "",
      email: "",
      username: "",
      psw: "",
      psw1: "",
      loading: false,
    };
  },
  mounted() {
    const { username, email, token } = this.$route.query;
    this.username = username;
    this.email = email;
    this.token = token;
    console.log(username, email, token);
  },
  created() {},
  methods: {
    findpassword() {
      this.$router.push("/findpassword");
    },
    goregister() {
      this.$router.push("/register");
    },
    // 重置密码
    async resetPassword() {
      if (this.loading) return;
      if (!this.psw) {
        this.$message.warning("请输入密码");
        return;
      }
      if (!this.psw1) {
        this.$message.warning("请确认密码");
        return;
      }
      if (this.psw1 !== this.psw) {
        this.$message.warning("两次输入的密码不一致");
        return;
      }
      this.loading = true;
      const result = await resetPsw({
        email: this.email,
        password: this.psw,
        token: this.token,
      });
      this.loading = false;
      if (result.success) {
        this.$message.success("修改密码成功");
        this.$router.push("/login");
      } else {
        this.$message.error("修改密码失败");
      }
    },
  },
};
</script>
<style>
</style>
